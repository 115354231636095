import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'libs/services/app.service';
import { APPS } from 'libs/utils/constants';

@Component({
  selector: 'lv-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'ConnectByLeaf';

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private appService: AppService
  ) {
    this.appService.appName = APPS.CONNECT_OFFICE;
    iconRegistry.addSvgIcon(
      'sorting',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/icons-sort.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'leaf',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-leaf.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'leafBlack',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-leaf-black.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'aside',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-sidebar.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'verticalMenu',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-more-horizontal.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'facebook',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-facebook.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'instagram',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-instagram.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'linkedin',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-linkedin.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'trash',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-trash-2.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'trash-can',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-trash.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'filter',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-filter.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'add-user',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-user-plus.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'check',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-check.svg'
      )
    );
    // console.log('Release version : ', environment.release);
  }
}
